.toggle {
  padding: 5px 15px !important;
}

.toggle div {
  vertical-align: top;
  display: inline;
}

.input:focus {
  outline: none;
}