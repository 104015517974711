.wizard_container {
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.wizard_wrapper {
  width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: #f0f0f0;
  border: solid 1px darkgray;
}

.row {
  height: 550px;
} 

.content {
  background-color: #ffffff;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 40px;
  height: 100%;
  border-radius: 2px;
}

.wizard_button {
 width: 100% !important;
}

.wizard_button_wrapper {
  position: relative;
  height: 100%;
}

.wizard_button_wrapper h2 {
  text-align: center;
}
