.small_pad {
  padding: 5px !important;
}

.loan_help {
  padding-left: 30px !important;
  padding-top: 20px;
  padding-right: 0px !important;
}

.toggle {
  padding: 10px 15px !important;
}

.toggle div {
  vertical-align: top;
  display: inline;
}

.grid_column label {
  display: block;
  font-size: 12px;
}

.input {
  width: 100% !important;
}