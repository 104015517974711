body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid_column label {
  display: block;
  font-size: 12px;
}

input, select {
  width: 100%;
  min-height: 30px;
  border-radius: 3px;
  border: solid 1px rgba(0,0,0,0.2);
  padding: 5px;
}

input:focus, select:focus {
  border: solid 1px rgb(30, 144, 255);
  outline: solid 1px rgba(30, 144, 255, 0.5);
}



