.input {
  width: 100%;
}

.textarea {
  width: 100%;
  max-width: 100%;
  max-height: 50px;
  min-height: 50px;
  resize: vertical;
  display: block;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.textarea:focus {
  border: solid 1px rgb(30, 144, 255);
  outline: solid 1px rgba(30, 144, 255, 0.5);
}