html, body {
 width: 100%;
 height: 100%;
 margin: 0;
 padding: 0;
 background-color: #ffffff;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: left;
  padding-top: 70px;
}


