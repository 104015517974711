.small_pad {
  padding: 5px !important;
}

.loan_help {
  padding-left: 30px !important;
  padding-top: 20px;
  padding-right: 0px !important;
}

.toggle {
  padding: 5px 15px !important;
}

.toggle div {
  vertical-align: top;
  display: inline;
}