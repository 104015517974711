.badge_icon {
  position: absolute;
  top: -7px;
  left: 7px;
  background-color: white;
  border: solid 1px rgb(209,209,209);
  box-shadow: none !important;
  border-radius: 500em!important;
  line-height: 1!important;
  padding: .4em 0!important;
  width: 2em!important;
  height: 2em!important;
}

.category_box {
  padding: 15px;
  border: solid 1px rgb(209,209,209);
  border-radius: 3px;
  background-color: rgba(0,0,0,0.015);
}

.category_row {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
}