.grid_column label {
  display: block;
  font-size: 12px;
}

.input {
  width: 100% !important;
}

.disabled {
  cursor: not-allowed;
}

.disabled > input{
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.toggle div {
  vertical-align: top;
  display: inline;
}

.no_bottom_pad {
  padding-bottom: 0 !important;
}

.no_top_pad {
  padding-top: 0 !important;
}
