.container {
  height: 100%;
}

.content_structure {
  position: relative;
  height: 100%;
}

.center_button {
  width: 300px !important;
}

.button_row {
  position: absolute;
  bottom: 30px;
}

.button_set {
  text-align: center;
}

.button_set button {
  width: 200px;
  text-align: center !important;
}

.wrapper {
  height: 100%;
}